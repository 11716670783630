import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition';
import { Cohorts, COHORT_KEY } from '@confluence/onboarding-helpers/entry-points/constants/cohorts';

const BOLD_NEW_PAGES_EXPERIMENT = 'confluence_onboarding_bold_new_pages_experiment';

const BOLD_NEW_PAGES_EXPERIMENT_AA = 'confluence_onboarding_bold_new_pages_aa';

/**
 * Eligibility Check Hook for Bold New Pages
 * @param {Boolean} fireExperimentExposure Optional param to fire exposure event. defaults to false
 * @returns {Boolean} User are eligible for bold new pages experiment if they are
 * - Logged In
 * - on Standard Edition
 * - Are part of Experiment Cohort
 */
export const useIsBoldPageTreeExperimentEligible = (fireExperimentExposure = false): boolean => {
	const { isLoggedIn, edition } = useSessionData();

	const isStandardLoggedInUser = isLoggedIn && edition === ConfluenceEdition.STANDARD;

	if (!isStandardLoggedInUser) {
		return false;
	}

	const boldNewPagesCohort = FeatureGates.getExperimentValue<Cohorts>(
		BOLD_NEW_PAGES_EXPERIMENT,
		COHORT_KEY,
		Cohorts.NOT_ENROLLED,
		{
			fireExperimentExposure,
		},
	);
	FeatureGates.getExperimentValue<Cohorts>(
		BOLD_NEW_PAGES_EXPERIMENT_AA,
		COHORT_KEY,
		Cohorts.NOT_ENROLLED,
		{
			fireExperimentExposure,
		},
	);

	return boldNewPagesCohort === Cohorts.EXPERIMENT;
};
